import React from 'react';
import Pagination from 'react-js-pagination';

const PaginatedDeviceTable = ({ 
  devices, 
  selectedDevices, 
  onDeviceSelect, 
  itemsPerPage = 8 
}) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  
  // 计算总数据量
  const totalItemsCount = devices.length;
  
  // 获取当前页的数据
  const getCurrentPageItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return devices.slice(startIndex, endIndex);
  };

  // 处理页码改变
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
       <div className="table-responsive" style={{ maxHeight: "300px", overflowY: "auto" }}>
              <table className="table table-hover table-wrap mb-0">
                <thead>
                  <tr>
                    <th>CareWindow User</th>
                    <th>Device S/N</th>
                    <th>Selected</th>
                  </tr>
                </thead>
                <tbody>
            {getCurrentPageItems().map((device) => (
              <tr key={device.id}>
                <td>{device.name}</td>
                <td>{device.serialNo}</td>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedDevices.includes(device.id)}
                    onChange={() => onDeviceSelect(device.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      {/* Pagination */}
      <div style={{ 
        display: 'flex', 
        justifyContent: 'flex-end',
        marginTop: '-1px',
        paddingRight: '10px'
      }}>
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={totalItemsCount}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
          innerClass="pagination mb-0"
        />
      </div>
    </div>
  );
};

export default PaginatedDeviceTable;