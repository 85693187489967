import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";

function PremiumSubscriptionPage() {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const location = useLocation();
  
  const queryParams = new URLSearchParams(location.search);
  const deviceId = queryParams.get('deviceId');

  const plans = [
    {
      id: 1,
      name: "Basic Premium",
      price: 4.99,
      features: [
        "Unlimited video calls",
        "Priority support",
        "Ad-free experience"
      ]
    },
    {
      id: 2,
      name: "Family Premium",
      price: 9.99,
      features: [
        "All Basic Premium features",
        "Up to 5 family members",
        "Shared calendar",
        "Group video calls"
      ]
    }
  ];

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    if (!selectedPlan) {
      toast.error("Please select a plan first");
      return;
    }

    setIsProcessing(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/process-payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'portalscope': 'b3NMWOVzfdRUjrW',
          'validatereq': 'randomString'
        },
        body: JSON.stringify({
          planId: selectedPlan.id,
          deviceId: deviceId,
          amount: selectedPlan.price
        })
      });

      if (!response.ok) {
        throw new Error('Payment processing failed');
      }

      toast.success("Payment processed successfully!");
    } catch (error) {
      console.error('Payment error:', error);
      toast.error("Payment processing failed. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="container-fluid">
      <Toaster />
      <div className="row">
        <div className="col-md-12 text-center">
          <h1 className="my-4">CareWindow Premium Features</h1>
          <p className="mb-5">Choose your premium plan and enhance your CareWindow experience</p>
        </div>
      </div>

      <div className="row justify-content-center mb-5">
        {plans.map((plan) => (
          <div className="col-md-5 mb-4" key={plan.id}>
            <div 
              className={`card h-100 cursor-pointer ${
                selectedPlan?.id === plan.id ? 'border-primary shadow' : ''
              }`}
              onClick={() => handleSelectPlan(plan)}
              style={{ cursor: 'pointer' }}
            >
              <div className="card-header">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="mb-0">{plan.name}</h3>
                  <p className="h3 mb-0">${plan.price}/mo</p>
                </div>
              </div>
              <div className="card-body">
                <ul className="list-unstyled">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="mb-2">
                      <i className="fa fa-check text-success me-2"></i>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="row justify-content-center">
        <div className="col-md-6">
          <form onSubmit={handlePaymentSubmit} className="mb-5">
            <div className="mb-3">
              <label className="form-label">Card Number</label>
              <input 
                type="text"
                className="form-control"
                placeholder="1234 5678 9012 3456"
                required
              />
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label">Expiry Date</label>
                <input 
                  type="text"
                  className="form-control"
                  placeholder="MM/YY"
                  required
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">CVC</label>
                <input 
                  type="text"
                  className="form-control"
                  placeholder="123"
                  required
                />
              </div>
            </div>

            <button
              type="submit"
              disabled={!selectedPlan || isProcessing}
              className={`btn btn-primary w-100 ${(!selectedPlan || isProcessing) ? 'opacity-50' : ''}`}
            >
              {isProcessing 
                ? 'Processing...' 
                : `Pay $${selectedPlan?.price || '0.00'} / month`}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PremiumSubscriptionPage;